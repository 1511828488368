.card1 {
  width: 10rem;
  height: 6rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 16rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.26);
  border: 7px ;
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  padding: 0px 26px 2rem 26px;
}
.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  margin-top: -4rem;

}
/* heading */
.portfolio span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolio span:nth-of-type(2) {
  color: var(--mildblack);
  font-size: 2.5rem;
  font-weight: bold;
}

/* slider */
.portfolio .swiper{
    overflow: visible!important;
}

.portfolio-slider {
  margin-top: 3rem;
  width: 100%;
}

.portfolio-slider .swiper-slide {
  width: 20rem;
}
.portfolio img {
  width: 19rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
  height: 10rem;
}

