:root
{
  --yellow: #F5C32C;
    --mildblack : #696969;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(193, 193, 196);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --blackCard: rgba(86, 85, 84, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
}

.button {
    background: linear-gradient(180deg, #000 26.71%, #242D49 99.36%);
    box-shadow: 0px 20px 24px 3px rgba(58, 58, 58, 0.42);
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 11px 26px 11px 26px;
  }
  
  .button:hover {
    background: white;
    cursor: pointer;
    border: 1px solid var(--mildblack);
    color: var(--mildblack);
  }
  @media screen and (max-width: 480px) {
    .App {
      padding: 0.5rem 1rem;

    }
  }

  

  