.q-wrapper{
    padding:  0 3rem 0 3rem;;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.q-heading{
    align-self: start;
}
.q-heading>*{
    font-size: 2rem;
    font-weight: bold;
}
.q-heading span:nth-of-type(2){
    color: var(--mildblack);
}

.q-blur1{
    left: 38rem;
    top: 16rem;
}
.q-blur2{
    top: 9rem;
    left: 0;
}
.qualification{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
    width: 30rem;
    height: 12rem;
    /* styles same as work.css */
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--blackCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
}
.qualification>img{
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
}
.qualification>span{
    color: var(--gray);
}


/* slider */
.q-wrapper .swiper {
    width: 100%;
    height: 70%;
}

.q-wrapper .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
}

.q-wrapper .swiper-pagination-bullet-active{
    background: var(--mildblack);
}

@media screen and (max-width: 480px){
    .q-wrapper{
        padding: 0;
    }
    .qualification>img{
        width: 70px;
        height: 70px;
    }
  }